export const useClickOutside = (elTargetRel, callback) => {
  if (!elTargetRel) {
    return
  }

  const listener = (e) => {
    if (
      e.target === elTargetRel.value ||
      e.composedPath().includes(elTargetRel.value)
    ) {
      // We clicked inside the modal/active region
      return
    }
    // at this point we clicked outside the modal
    if (typeof callback === 'function') {
      callback()
    }
  }

  onMounted(() => {
    window.addEventListener('click', listener)
  })
  onBeforeMount(() => {
    window.removeEventListener('click', listener)
  })

  return { listener }
}
